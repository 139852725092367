import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "../../../components/ErrorMessage";
import styles from "./SponsorNameForm.module.scss";
import { SponsorIdInput } from "./SponsorIdInput";
import { SponsorNameInput } from "./SponsorNameInput";
import { getErrorMessage } from "../../../utils/formsUtils";
import { Spinner } from "../../../components/common/Spinner";
import { sanitizeValue } from "../../../utils/replacement";
import { useRegistrationStore } from "../../registration/registrationStore";
import { Button } from "../../../components/common/Button";
import MandatoryFieldsAnnotation from "../../../components/MandatoryFieldsAnnotation/MandatoryFieldsAnnotation";
import { useShallow } from "zustand/react/shallow";

type SponsorInformationFormProps = {
  onSubmit: () => void;
  isCheckPending: boolean;
  isErrorMessageVisible: boolean;
  errorMessage: string;
};

export const SponsorInformationForm = ({
  onSubmit,
  isCheckPending,
  isErrorMessageVisible,
  errorMessage,
}: SponsorInformationFormProps) => {
  const { t } = useTranslation();
  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
  } = useFormContext();
  const readOnlyRegistration = useRegistrationStore(
    useShallow((state) => state.readOnlyMode)
  );

  return (
    <>
      {isCheckPending ? <Spinner /> : null}
      <MandatoryFieldsAnnotation />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.sponsorInformationForm}
        noValidate
      >
        <div
          className={styles.sponsorInformationWrapper}
          data-testid="sponsor-form"
        >
          <Controller
            control={control}
            name="sponsorId"
            render={({ field: { onBlur, onChange, value, ref } }) => (
              <SponsorIdInput
                disabled={readOnlyRegistration}
                error={errors.sponsorId}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(sanitizeValue(e));
                }}
                value={value as string}
                ref={ref}
              />
            )}
          />
          {errors.sponsorId && (
            <ErrorMessage data-testid="sponsor-id-error">
              {t(getErrorMessage(errors.sponsorId))}
            </ErrorMessage>
          )}
          <Controller
            control={control}
            name="sponsorNameFirstLetters"
            render={({ field: { onBlur, onChange, value, ref } }) => (
              <SponsorNameInput
                disabled={readOnlyRegistration}
                error={errors.sponsorNameFirstLetters}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(sanitizeValue(e));
                }}
                value={value as string}
                ref={ref}
              />
            )}
          />
          {errors.sponsorNameFirstLetters && (
            <ErrorMessage data-testid="sponsor-name-error">
              {t(getErrorMessage(errors.sponsorNameFirstLetters))}
            </ErrorMessage>
          )}
          <Button
            id="verify-sponsor-submit-button"
            hasDisabledStyle={isCheckPending || !isValid}
            disabled={isCheckPending}
            type="submit"
          >
            {t("VERIFY_SPONSOR")}
          </Button>
        </div>
      </form>
      {isErrorMessageVisible && <ErrorMessage>{t(errorMessage)}</ErrorMessage>}
    </>
  );
};
